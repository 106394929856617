/* CSS for Login Container */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background: rgb(2,0,36);
    background: linear-gradient(90deg,  #000101 , #6c0404 ); */
    background-image: url('/public/assets/loginbg.jpg'); 
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center center;
}

.login-wrapper {
    background: #ffffff20;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.signin-message {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ff0707;
}

/* Form Container */
.email-container,
.password-container {
    text-align: left;
    margin-bottom: 20px;
}

label {
    display: block;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 5px;
}

.email-input,
.password-input {
    width: 93%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

/* Remember Me */
.remember-me {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #444;
}

.remember-checkbox {
    margin-right: 8px;
}

/* Sign In Button */
.signin-button {
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    border: none;
    height: 40px;
    width: 20p;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signin-button:hover {
    background: rgb(2,0,36);
    background: linear-gradient(90deg,  #c6dbdb , #998383 );
}

/* Modal Background */
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
}

/* Modal Content */
.modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}
