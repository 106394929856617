@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #000000;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.event_form .MuiSelect-select{
 background: #1b1717 !important;
 margin-top: -22px !important;
}

.event_form .MuiOutlinedInput-notchedOutline.css-9425fu-MuiOutlinedInput-notchedOutline
{
  margin-top: -22px !important;
  
 }

 .event_form .css-1faml0z-MuiSvgIcon-root-MuiSelect-icon{
  top: -8px !important;
 }

 .edit_btn{
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 2px;
  background-color: #1cb91e;
  border: none;
  outline: none;
  padding-top: 3px;
}
 .edit_btn img{
  width: 18px;
  
}

.remove_btn{
  cursor: pointer;
  border-radius: 2px;
  background-color: #E50914;  
  height: 30px;
  width: 30px;
  border: none;
  outline: none;
  padding-top: 3px;
 }
.remove_btn img{
  width: 18px;
 }

 .assign_btn{
  background-color: #E50914;
  color: white;
  height: 40px;
  width: 70px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
 }
 .assign_btn1{
  background-color: #E50914;
  color: white;
  height: 40px;
  width: 70px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
 }

 .event-img{
  width: 300px;
  height: 300px;
  border-radius: 4px;
 }

 .logout-btn{
  padding: 10px 15px;
  border-radius: 4px;
  color: white;
  background-color: #E50914;
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
 }

 .main-dashboard{
  height: 90vh;
  background: transparent !important;
  overflow-y: scroll;
  padding-bottom: 5%;
 }

 .main-dashboard::-webkit-scrollbar{
  display: none;
 }

 .add_btn{
  padding: 4px 10px;
  background: #E50914;
  border: none;
  outline: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
 }

 .pagination-btn{
  padding: 4px 10px;
  background: #575d61;
  border: none;
  outline: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
 }

 .custom_loader{
  font-size: 1.5rem;
 }

 .no_data_container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  height: 50px;
  color: red;
}

.no_data_container_left{
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   width: 100%;
   font-size: 1rem;
   height: 50px;
   color: red;

 }


 .dlt_btn{
    background: red;
    border: none;
    outline: none;
    padding: 2px;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    cursor: pointer;
 }

 .dlt_btn img{
  width: 20px;
 }